<template>
  <div class="defaultUpload">
    <h2>Добавить статью</h2>
      <ElemInput
        valueName="title"
        @changeValue="changeValue"
        :valueProp="title"
        label="Заголовок"
      />
      
      <ElemTextarea
        valueName="description"
        @changeValue="changeValue"
        :valueProp="description"
        label="Описание"
      />

      <ElemInput
        valueName="author"
        @changeValue="changeValue"
        :valueProp="author"
        label="Автор"
      />

      <ElemSelect
        valueName="status"
        @changeValue="changeValue"
        :valueProp="status"
        label="Статус"
        :options="statusOptions"
      />

      <span class="name">Текст</span>

      <TextEditor v-model="content" />

      <button type="button" v-on:click="createStory">Загрузить</button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import stories from "@/composables/views/stories.ts";

export default {
  setup() {
    const {
      createStory,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
    } = stories();
    return {
      createStory,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
    };
  },
  data: () => ({
  }),
  components: {
    TextEditor,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
  created() {},
};
</script>