<template>
  <div class="PickGallery">
    <div class="PickGallery-content">
      <img class="cross" @click="close" src="@/assets/cross.png" />
      <ImagePick
        v-for="dates in gallery"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        @pickImage="pickImage"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/images.js";
import ImagePick from "@/components/gallery/ImagePick.vue";

export default {
  data() {
    return {
      gallery: [],
    };
  },
  components: {
    ImagePick,
  },
  methods: {
    pickImage(image) {
      console.log(image);
      this.$emit("pickImage", image);
    },
    close(){
      this.$emit("close");
    }
  },
  async created() {
    let response = await Api.getModels({});
    this.gallery = response.data;
  },
};
</script>


<style lang="scss">
.PickGallery {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0px;
  top: 0px;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &-content {
    position: relative;
    width: 600px;
    height: 600px;
    background: white;
    flex-flow: row wrap;
    overflow-y: auto;
    justify-content: center;

    .cross {
      position: absolute;
      right: -20px;
      top: -20px;
      cursor: pointer;
    }
  }
}
</style>