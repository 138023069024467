import Api from "@/api/auth";
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
import store from '@/store'

export default function main() {
  let router = useRouter()
  let email = ref("");
  let password = ref("");

  const auth = async () => {
    const response = await Api.auth({ email: email.value, password: password.value });
    if (response.data !== null) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user_id);
      store.commit("setToken", response.data.token);
      store.commit("setUser", response.data.user_id);
      router.push("/news/");
    } else {
      alert("bad password");
    }
  };


  return {
    auth,
    email,
    password,
  };
}
