import { createStore } from 'vuex'
import router from '../router';

const store = createStore({
  state() {
    return {
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId")
    }
  },
  mutations: {
    logout(state) {
      state.token = null;
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      router.push('/login')
    },
    setToken(state, token){
      state.token = token;
    },
    setUser(state, userId){
      state.userId = userId;
    }
  }
})

export default store;