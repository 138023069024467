import Api from "@/api/users";
import { useRouter } from "vue-router";
import { ref } from "vue";

export default function users(id) {
    let models = ref([]);

    let router = useRouter()

    let baseUrl = process.env.VUE_APP_API;
    let openPickImage = ref(false)

    let login = ref("");
    let email = ref("");
    let avatar = ref("");
    let name = ref("");

    const createUser = async () => {
        await Api.createModel({
            login: login.value,
            email: email.value,
            avatar: avatar.value,
            name: name.value,
        });

        router.push("/settings/");
    };

    const updateUser = async () => {

        await Api.updateModel(id, {
            login: login.value,
            email: email.value,
            avatar: avatar.value,
            name: name.value,
        });

        alert("Пользователь успешно обновлен");
    }


    const deleteUser = async () => {
        let isDelete = confirm("Вы действительно хотите удалить пользователя?");

        if (isDelete === true) {
            await Api.deleteModel(id);
            router.push("/settings/");
        }
    }


    const getUser = async () => {
        let response = await Api.getModel(id);

        login.value = response.data.login;
        email.value = response.data.email;
        avatar.value = response.data.avatar;
        name.value = response.data.name;
    }

    const getUsers = async () => {
        let response = await Api.getModels({});
        models.value = response.data;
    }

    return {
        openPickImage, models, createUser, updateUser, deleteUser, getUser, getUsers, baseUrl, login, email, avatar, name
    };
}
