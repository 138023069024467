<template>
  <div class="main">
    <router-view name="header"></router-view>
    <router-view name="leftMenu"></router-view>
    <router-view />
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import "@/scss/main.scss";

export default {
  
};
</script>
