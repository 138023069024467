<template>
  <div class="HomeView">WELCOME TO FOXADMIN</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
  },
};
</script>
