<template>
  <div class="SettingsView">
    <span>Настройка пользователя</span>

    {{ login }}

    <span>Изменить аватар: </span>
    {{ avatar }}

    <button class="addTitleImage" @click="openPickImage = true" type="button">
      Титульное изображение
    </button>

    <img v-if="avatar" :src="baseUrl + 'storage/images/' + avatar" />

    <PickGallery
      v-if="openPickImage"
      @close="openPickImage = false"
      @pickImage="pickImage"
    />

    <button @click="updateUser">UPDATE</button>
  </div>
</template>

<script>
import users from "@/composables/views/users.ts";
import PickGallery from "@/components/gallery/PickGallery";

export default {
  setup() {
    const { getUser, login, updateUser, avatar, email, name, baseUrl } =
      users(1);
    return {
      getUser,
      login,
      updateUser,
      avatar,
      email,
      name,
      baseUrl,
    };
  },
  data() {
    return {
      openPickImage: false,
    };
  },
  components: { PickGallery },
  methods: {
    pickImage(image) {
      this.avatar = image;
      this.openPickImage = false;
    },
  },
  async created() {
    this.getUser();
  },
};
</script>
