<template>
  <router-link :to="{ name: routeName, params: { id: dates.id } }">
    <div class="blockView">
      <input type="checkbox" />
      <div class="body">
        <div class="body-info">
          <span class="body-info-title">{{ interiorTitle }}</span>
          <div class="body-info-author">
            <span v-if="dates.author">Автор: {{ dates.author }}</span>
            <span>17:05 29.10.2020</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: { dates: Object, routeName: String, title: String },
  data() {
    return {
      interiorTitle: "",
    };
  },
  methods: {
    getValues() {
      if (!this.title) {
        this.interiorTitle = this.dates.title;
      } else {
        this.interiorTitle = this.title;
      }
    },
  },
  created() {
    this.getValues();
  },
};
</script>
