<template>
  <div class="GalleryView">
    <div class="header">
      <span>Галерея</span>

      <div class="search">
        <img src="@/assets/icon1.svg" />
        <input type="text" placeholder="Поиск..." />
      </div>
    </div>

    <div class="images">
      <ImageGallery
        v-for="dates in gallery"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        @deleteImage="deleteImage"
      />
    </div>
    <span>Добавить изображение</span>
    <input type="file" class="textImage" v-on:change="UploadImg" />
    <!-- <FoldersView /> -->
  </div>
</template>

<script>
import Api from "@/api/images.js";

import ImageGallery from "@/components/gallery/ImageView.vue";

export default {
  data() {
    return {
      gallery: [],
    };
  },
  components: {
    ImageGallery: ImageGallery,
    // FoldersView: FoldersView,
  },
  methods: {
    async UploadImg() {
      var formData = new FormData();
      var imagefile = document.getElementsByClassName("textImage")[0];
      formData.append("image", imagefile.files[0]);

      await Api.uploadImg(formData);
      let response = await Api.getModels({});
      this.gallery = response.data;
    },
    async deleteImage(id) {
      let isDelete = confirm("Вы действительно хотите удалить изображение?");

      if (isDelete === true) {
        await Api.deleteModel(id);

        let response = await Api.getModels({});
        this.gallery = response.data;
      }
    },
  },
  mounted: function () {},
  async created() {
    let response = await Api.getModels({});
    this.gallery = response.data;
  },
};
</script>