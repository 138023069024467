<template>
  <PickGallery
    v-if="openPickImage"
    @close="openPickImage = false"
    @pickImage="pickImage"
  />
  <div class="TextEditor">
    <div class="buttons" v-if="editor">
      <button
        type="button"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        B
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        I
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        H1
      </button>

      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        H2
      </button>

      <button
        type="button"
        @click="editor.chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        Code
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button>

      <button type="button" @click="openPickImage = true">img</button>
    </div>

    <editor-content :editor="editor" />
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import { Editor, EditorContent } from "@tiptap/vue-3";
import PickGallery from "@/components/gallery/PickGallery";

export default {
  components: {
    EditorContent,
    PickGallery,
  },

  data() {
    return {
      editor: null,
      openPickImage: false,
    };
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  emits: ["update:modelValue"],

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [Image, StarterKit],
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
      this.addImage(image);
    },
    addImage(img) {
      var url = process.env.VUE_APP_API + "storage/images/" + img;
      if (url) {
        this.editor
          .chain()
          .focus()
          .setImage({
            src: url,
            HTMLAttributes: {
              class: "my-custom-class",
            },
          })
          .run();
      }
    },
  },
};
</script>
