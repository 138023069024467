<template>
  <div class="topMenu">
    <div class="search">
      <span>Все статьи</span>
      <img src="@/assets/icon1.svg" />
      <input type="text" placeholder="Поиск..." />
    </div>

    <div class="buttons">
      <div class="selectAll">
        <span>Выделить все</span>
        <img src="@/assets/icon2.svg" />
      </div>

      <div class="delete">
        <span>Удалить</span>
        <img src="@/assets/icon3.svg" />
      </div>

      <router-link :to="url">
        <button class="button">Добавить {{ page }}</button>
      </router-link>

      <div class="sort">
        <div @click="showSort = true" class="sort-body">
          Сортировать
          <img src="@/assets/icon5.svg" />
        </div>

        <div v-if="showSort" class="sort-options">
          <span @click="sort('date')">По дате</span>
          <span @click="sort('author')">По автору</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    page: String,
    url: String,
  },
  data() {
    return {
      showSort: false,
    };
  },
  methods: {
    openSort() {},
    sort(type) {
      this.$emit("sort", type);
    },
  },
};
</script>
