<template>
  <div class="UsersView">
    <div class="defaultView">
      <TopMenu page="Пользователи" url="/stories/upload" @sort="sort" />
      <div class="list">
        <BlockView
          v-for="dates in models"
          :key="dates.id"
          :dates="dates"
          :title="dates.login"
          routeName="users-edit"
        ></BlockView>
      </div>
    </div>
  </div>
</template>

<script>
import users from "@/composables/views/users.ts";
import BlockView from "@/components/BlockView.vue";

export default {
  setup() {
    const {
      login,
      avatar,
      email,
      name,
      baseUrl,
      getUsers,
      models,
    } = users();
    return {
      login,
      avatar,
      email,
      name,
      baseUrl,
      getUsers,
      models,
    };
  },
  data() {
    return {
      openPickImage: false,
    };
  },
  components: { BlockView },
  methods: {
  },
  async created() {
    this.getUsers();
  },
};
</script>
