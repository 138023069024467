<template>
  <div class="LeftMenu">
    <div class="logo">
      <img src="../assets/logo-1.svg" />
    </div>

    <div class="user">
      <img class="avatar" v-if="avatar" :src="baseUrl + 'storage/images/' + avatar" />
      <img v-else src="../assets/user.svg" />
      <div class="user-name">
        <span>Иван Иванов</span>
        <span>Администратор</span>
      </div>
    </div>

    <div class="links">
      <router-link to="/news/"
        ><div class="links-item"><span>Новости</span></div></router-link
      >
      <router-link to="/programming/"
        ><div class="links-item">
          <span>Программирование</span>
        </div></router-link
      >
      <router-link to="/stories/"
        ><div class="links-item"><span>Рассказы</span></div></router-link
      >
      <router-link to="/techtasks/"
        ><div class="links-item">
          <span>Технические задания</span>
        </div></router-link
      >
      <router-link to="/gallery/"
        ><div class="links-item"><span>Галерея</span></div></router-link
      >
      <router-link to="/users/"
        ><div class="links-item"><span>Пользователи</span></div></router-link
      >
      <router-link to="/settings/"
        ><div class="links-item"><span>Настройки</span></div></router-link
      >
    </div>
  </div>
</template>
<script>
import users from "@/composables/views/users.ts";
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const { baseUrl, avatar, getUser, email, name } = users(store.state.userId);
    return {
      avatar,
      email,
      name,
      getUser,
      baseUrl,
    };
  },
  async created() {
    this.getUser();
  },
};
</script>