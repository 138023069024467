<template>
  <footer class="FooterComp">
    <img src="@/assets/logo.svg" /><span>Разработано компанией Fox Studio 2020</span>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>
