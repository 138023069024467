<template>
  <div @mouseover="show_add" class="ImagePick">
    <img :src="baseUrl + 'storage/images/' + dates.folder + '/' + dates.url" />
    <span>{{ dates.description }}</span>
    <button @click="pickImage(dates)">Выбрать</button>
  </div>
</template>
<script>
export default {
  props: ["dates"],
  data() {
    return {
      baseUrl: process.env.VUE_APP_API,
    };
  },

  methods: {
    show_add() {},
    pickImage(dates) {
      console.log(dates);
      this.$emit("pickImage", dates.folder + "/" + dates.url);
    },
  },
};
</script>



<style lang="scss" scope>
.ImagePick {
  position: relative;
  flex-flow: column nowrap;
  align-items: center;
  margin: 20px 28px 0px 0px;
  width: 150px;
  height: 180px;
  border: 1px solid #a5a0a0;
  border-radius: 5px;
  background: #e4e0e0;

  button {
    margin: 0px !important;
  }
}
.ImagePick img:nth-of-type(1) {
  width: 140px;
  height: 150px;
  object-fit: cover;
  margin: 4px 0px 0px 0px;
}
.ImagePick span {
  font: 16px/22px OpenSans-Semibold;
  margin: 7px auto 0px 5px;
  color: #a5a0a0;
}
</style>