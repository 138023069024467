<template>
  <div class="defaultEdit">
    <h2>Настройка пользователя</h2>

    <ElemInput
      valueName="password"
      @changeValue="changeValue"
      :valueProp="password"
      label="Пароль"
    />

    <ElemSelect
      valueName="status"
      @changeValue="changeValue"
      :valueProp="category"
      label="Статус"
      :options="statusOptions"
    />

    <span>Изменить аватар: </span>
    {{ avatar }}

    <button class="addTitleImage" @click="openPickImage = true" type="button">
      Титульное изображение
    </button>

    <img v-if="avatar" :src="baseUrl + 'storage/images/' + avatar" />

    <PickGallery
      v-if="openPickImage"
      @close="openPickImage = false"
      @pickImage="pickImage"
    />

    <button class="update" type="button" v-on:click="updateUser">
      Обновить
    </button>
  </div>
</template>

<script>
import PickGallery from "@/components/gallery/PickGallery";
import ElemInput from "@/components/elements/elemInput";
import ElemSelect from "@/components/elements/elemSelect";
import users from "@/composables/views/users.ts";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();

    const {
      getUser,
      login,
      updateUser,
      avatar,
      email,
      name,
      baseUrl,
      getUsers,
      models,
      openPickImage,
    } = users(route.params.id);
    return {
      getUser,
      login,
      updateUser,
      avatar,
      email,
      name,
      baseUrl,
      getUsers,
      models,
      openPickImage,
    };
  },
  data() {
    return {};
  },

  components: {
    PickGallery,
    ElemInput,
    ElemSelect,
  },
  mounted: function () {},
  async created() {
    this.getUser();
  },

  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.avatar = image;
      this.openPickImage = false;
    },
  },
};
</script>