<template>
  <div class="defaultView">
    <TopMenu page="Новость" url="/news/upload" @sort="sort" />
    <div class="list">
      <BlockView
        v-for="dates in models"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        routeName="news-edit"
      ></BlockView>
    </div>
  </div>
</template>

<script>
import BlockView from "@/components/BlockView.vue";
import TopMenu from "@/components/TopMenu.vue";
import news from "@/composables/views/news.ts";

export default {
  setup() {
    const { getAllNews, models } = news();
    return {
      getAllNews,
      models,
    };
  },
  data() {
    return {};
  },
  name: "NewsView",

  components: {
    BlockView,
    TopMenu,
  },
  mounted: function () {},
  methods: {
    async sort(type) {
      console.log(type);
    },
  },
  async created() {
    this.getAllNews();
  },
};
</script>
