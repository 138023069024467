import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import GalleryView from '@/views/GalleryView.vue'
import SettingsView from '@/views/SettingsView.vue'

import UsersView from '@/views/UsersView.vue'
import UsersEdit from '@/views/users/UsersEdit.vue'

import NewsView from '@/views/news/NewsView.vue'
import NewsEdit from '@/views/news/NewsEdit.vue'
import NewsUpload from '@/views/news/NewsUpload.vue'

import StoriesView from '@/views/stories/StoriesView.vue'
import StoriesEdit from '@/views/stories/StoriesEdit.vue'
import StoriesUpload from '@/views/stories/StoriesUpload.vue'

import ProgrammingView from '@/views/programming/ProgrammingView.vue'
import ProgrammingEdit from '@/views/programming/ProgrammingEdit.vue'
import ProgrammingUpload from '@/views/programming/ProgrammingUpload.vue'

import FooterComp from "@/components/layouts/FooterComp.vue";
import LeftMenu from "@/components/LeftMenu.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: LoginView,
      footer: FooterComp
    },
  },
  {
    path: '/gallery',
    name: 'gallery',
    components: {
      leftMenu: LeftMenu,
      default: GalleryView,
      footer: FooterComp
    },
  },
  {
    path: '/news',
    name: 'news',
    components: {
      leftMenu: LeftMenu,
      default: NewsView,
      footer: FooterComp
    },
  },
  {
    path: '/news/edit/:id',
    name: 'news-edit',
    components: {
      leftMenu: LeftMenu,
      default: NewsEdit,
      footer: FooterComp
    },
  },
  {
    path: '/news/upload',
    name: 'news-upload',
    components: {
      leftMenu: LeftMenu,
      default: NewsUpload,
      footer: FooterComp
    },
  },
  {
    path: '/stories',
    name: 'stories',
    components: {
      leftMenu: LeftMenu,
      default: StoriesView,
      footer: FooterComp
    },
  },
  {
    path: '/stories/edit/:id',
    name: 'stories-edit',
    components: {
      leftMenu: LeftMenu,
      default: StoriesEdit,
      footer: FooterComp
    },
  },
  {
    path: '/stories/upload',
    name: 'stories-upload',
    components: {
      leftMenu: LeftMenu,
      default: StoriesUpload,
      footer: FooterComp
    },
  },
  {
    path: '/programming',
    name: 'programming',
    components: {
      leftMenu: LeftMenu,
      default: ProgrammingView,
      footer: FooterComp
    },
  },
  {
    path: '/programming/edit/:id',
    name: 'programming-edit',
    components: {
      leftMenu: LeftMenu,
      default: ProgrammingEdit,
      footer: FooterComp
    },
  },
  {
    path: '/programming/upload',
    name: 'programming-upload',
    components: {
      leftMenu: LeftMenu,
      default: ProgrammingUpload,
      footer: FooterComp
    },
  },
  {
    path: '/users',
    name: 'users',
    components: {
      leftMenu: LeftMenu,
      default: UsersView,
      footer: FooterComp
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    components: {
      leftMenu: LeftMenu,
      default: UsersEdit,
      footer: FooterComp
    },
  },
  {
    path: '/settings',
    name: 'settings',
    components: {
      leftMenu: LeftMenu,
      default: SettingsView,
      footer: FooterComp
    },
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && store.state.token === null) {
    next({ name: 'login' })
  } else next()
})

export default router
