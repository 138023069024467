<template>
  <div class="defaultEdit">
    <h2>Редактировать статью</h2>

    <ElemInput
      valueName="title"
      @changeValue="changeValue"
      :valueProp="title"
      label="Заголовок"
    />

    <ElemTextarea
      valueName="description"
      @changeValue="changeValue"
      :valueProp="description"
      label="Описание"
    />

    <ElemInput
      valueName="author"
      @changeValue="changeValue"
      :valueProp="author"
      label="Автор"
    />

    <ElemSelect
      valueName="category"
      @changeValue="changeValue"
      :valueProp="category"
      label="Категория"
      :options="categoryOptions"
    />

    <ElemSelect
      valueName="status"
      @changeValue="changeValue"
      :valueProp="status"
      label="Статус"
      :options="statusOptions"
    />

    <TextEditor v-model:modelValue="text" />

    <button class="update" type="button" v-on:click="updateArticle">
      Обновить
    </button>
    <button class="delete" type="button" v-on:click="deleteArticle">
      Удалить
    </button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import programming from "@/composables/views/programming.ts";

export default {
  setup() {
    const {
      getArticle,
      deleteArticle,
      updateArticle,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
    } = programming();
    return {
      getArticle,
      deleteArticle,
      updateArticle,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
    };
  },
  data() {
    return {};
  },

  components: {
    TextEditor,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  mounted: function () {},
  async created() {
    this.getArticle();
  },
  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
};
</script>