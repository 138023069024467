<template>
  <div class="defaultView">
    <TopMenu page="Статью" url="/programming/upload" @sort="sort" />
    <div class="list">
      <BlockView
        v-for="dates in models"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        routeName="programming-edit"
      ></BlockView>
    </div>
  </div>
</template>

<script>
import BlockView from "@/components/BlockView.vue";
import TopMenu from "@/components/TopMenu.vue";
import programming from "@/composables/views/programming.ts";

export default {
  setup() {
    const { getArticles, models } = programming();
    return {
      getArticles,
      models,
    };
  },
  data() {
    return {};
  },
  name: "ProgrammingView",

  components: {
    BlockView,
    TopMenu,
  },
  mounted: function () {},
  methods: {
    async sort(type) {
      console.log(type);
    },
  },
  async created() {
    this.getArticles();
  },
};
</script>
