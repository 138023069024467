<template>
  <div class="LoginView">
    {{count}}
    <div class="container">
      <img class="cross" src="@/assets/cross.svg" />
      <span class="title">Войти в аккаунт</span>
      <input
        class="container-input"
        v-model="email"
        type="text"
        name="email"
        placeholder="Логин или электронная почта"
      />
      <input
        class="container-input"
        v-model="password"
        type="password"
        name="password"
        placeholder="Пароль"
      />
      <div class="actions">
        <input type="checkbox" /><span>Запомнить</span>
        <button v-on:click="auth">Войти</button>
      </div>
      <span class="band"></span>

      <div class="reg">
        <span>Забыли пароль?</span>
        <a href="/registration">Зарегиcтрироваться</a>
      </div>

      <img class="logo" src="@/assets/logo-1.svg" />
    </div>
  </div>
</template>

<script>
import login from "@/composables/views/login.ts";

export default {
  name: "LoginView",
  setup() {
    const { auth, email, password } = login();
    return { auth, email, password };
  },
  components: {},
  computed: {

count() {

return this.$store.state.token;

},

},
};
</script>
