<template>
  <div :class="className + ' elemTextarea'">
    <label :label-for="elId">
      {{ label }}
    </label>
    <textarea :disabled="disabled" :id="elId" v-model="value" />
    <span class="error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "elemTextarea",
  props: {
    disabled: {
      default: false,
    },
    elId: {
      default: "defaultInput",
    },
    label: {
      default: null,
    },
    error: {
      default: null,
    },
    valueName: {
      default: null,
    },
    valueProp: {
      default: null,
    },
    className: {
      default: null,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    valueProp(value) {
      this.value = value;
    },
    value(value) {
      this.$emit("changeValue", this.valueName, value);
    },
  },
  methods: {
  },
  created() {
    this.value = this.valueProp;
  },
};
</script>
